/** @jsx jsx */
import { jsx, Box, Container, Flex, Styled, Text } from "theme-ui"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import BackgroundImage from "gatsby-background-image"
import { HelmetDatoCms } from "gatsby-source-datocms"

import {
  Hero,
  Overlay,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Layout,
  Section,
  Main,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import { ColorBar, LeadershipGrid } from "../components/elements"

const Leadership = ({ data }) => {
  const page = data.datoCmsLeadership

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Main>
        <Hero>
          <BackgroundImage Tag="div" fluid={data.datoCmsAsset.fluid}>
            <Container
              sx={{ position: "relative", px: "1em", py: [5, 6], zIndex: 10 }}
            ></Container>
            <Overlay sx={{ opacity: 1 }} />
          </BackgroundImage>
          <ColorBar sx={{ position: "absolute", bottom: 0, left: 0 }} />
        </Hero>

        <Box sx={{ mt: [-5, -6] }}>
          <Container sx={{ px: "1em" }}>
            <Flex
              sx={{
                width: ["full"],
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: ["full"] }}>
                <Box
                  sx={{
                    mx: "auto",
                    width: ["140px", "180px", "220px"],
                    height: ["140px", "180px", "220px"],
                  }}
                >
                  <Img
                    fluid={page.portrait.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    sx={{
                      borderColor: "white",
                      borderStyle: "solid",
                      borderWidth: [4, 5, 6],
                      borderRadius: "full",
                      boxShadow: "lg",
                      width: ["140px", "180px", "220px"],
                      height: ["140px", "180px", "220px"],
                    }}
                  />
                </Box>
              </Box>
              <Box sx={{ textAlign: "center", width: ["full", "4/5", "2/3"] }}>
                <Styled.h2 as="h1">
                  {`${page.fullName}, ${page.credentials}`}
                </Styled.h2>
                {page.roleTitle}
              </Box>
            </Flex>
          </Container>
        </Box>

        {/* Bio */}
        <Box id="bio" as="article" py={4}>
          <Container sx={{ px: "1em" }}>
            <Flex
              sx={{
                width: ["full"],
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: ["full", "4/5", "2/3"] }}>
                <Text
                  sx={{ width: ["full"] }}
                  dangerouslySetInnerHTML={{
                    __html: page.contentNode.childMarkdownRemark.html,
                  }}
                />
              </Box>
            </Flex>
          </Container>
        </Box>

        {/* Leadership Grid */}
        <Section id="leadership" sx={{ bg: "warmgray" }} data-datocms-noindex>
          <Container sx={{ px: "1em" }}>
            <LeadershipGrid />
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default Leadership

export const query = graphql`
  query leadershipPageQuery($slug: String!) {
    datoCmsLeadership(slug: { eq: $slug }) {
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      credentials
      fullName
      id
      roleTitle
      slug
      meta {
        status
      }
      portrait {
        fluid(maxWidth: 400, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      seoMetaTags {
        tags
      }
    }
    datoCmsAsset(id: { eq: "DatoCmsAsset-1403893" }) {
      fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
        ...GatsbyDatoCmsFluid
      }
    }
  }
`
